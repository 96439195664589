import React from 'react';
import PropTypes from 'prop-types';
import otbLogo from 'erpcore/assets/images/otb-logo-hero.png';
import styles from './Hero.module.scss';

const Hero = ({ className }) => {
    return (
        <div className={`${styles.hero} ${className || ''}`}>
            <div className={styles.hero__content}>
                {/* <Svg icon="MyZoneErpHorizontalWhite" className={styles.hero__logo} /> */}
                <img className={styles.hero__logo} src={otbLogo} alt="otb logo" />
            </div>
        </div>
    );
};

Hero.defaultProps = {
    className: ''
};

Hero.propTypes = {
    className: PropTypes.string
};

export default Hero;
